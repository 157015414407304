import React, {Component, useState} from 'react';
import {Box, Divider, Stack} from "@mui/material";
import AddToBasket from "./AddToBasket";
import {useSelector} from "react-redux";
import ProductDescription from "./ProductDescription";

function ProductsRows(props) {
    const items = useSelector((state) => state.basket.items);
    const rows = {};
    const [imageSrc, setImageSrc] = useState(false);

    return <Stack direction={'column'} spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
        {props.products.map(item=>{
            const inOrder = (items[item.code] !== undefined) ? items[item.code].quant:'';
            const isFocused = (props.focused && props.focused.code === item.code);
            return <Product {...props}
                            ref={(ref)=>{rows[item.code] = ref}}
                            key={'product_'+item.code}
                            item={item}
                            inOrder={inOrder}
                            isFocused={isFocused}
                            onImage={(image)=>{setImageSrc(image)}}
                            brothers={rows}/>;
        })}
        {imageSrc && (
            <div id={'product-row-big-image'}><img src={imageSrc}  alt={'preview'}/></div>
        )}
    </Stack>;
}

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: this.props.isFocused,
            inOrder: this.props.inOrder,
        }
    }

    render() {
        const item = this.props.item;
        const imageDimension = 100;

        return <Box className={'product-row' + (this.state.inOrder?' inOrder':'') + (this.state.focused?' focused':'')}>
            <Stack direction={'row'} spacing={2}>
                <Box sx={{cursor: 'pointer'}} onClick={()=>{
                    this.props.onFocused(item, 'detail');
                }}>
                    <img
                        onMouseOver={()=>{
                            this.props.onImage(this.props.api.getImageSrc(item.code, 600));
                        }}
                        onMouseOut={()=>{this.props.onImage(false)}}
                        src={this.props.api.getImageSrc(item.code, imageDimension)}  alt={item.name}/>
                </Box>
                <ProductDescription item={item} />
                <AddToBasket
                    type={'row'} {...this.props}
                    currency={this.props.api.user.currency}
                    focused={this.state.focused}
                    handleNext = {()=>{
                        const newFocused = this.props.handleNext();
                        let row = this.props.brothers[newFocused.code];
                        if (row && (row.props.item.code !== item.code)) {
                            row.setState({focused: true});
                            if (row.code !== item.code) this.setState({focused: false})
                        }
                    }}
                    handlePrev = {()=>{
                        const newFocused = this.props.handlePrev();
                        let row = this.props.brothers[newFocused.code];
                        if (row && (row.props.item.code !== item.code)) {
                            row.setState({focused: true});
                            this.setState({focused: false})
                        }
                    }}
                    onFocused = {(item)=>{
                        if (this.state.focused === false) this.setState({focused: true});
                        const old = this.props.onFocused(item);
                        if (old && (old.code !== this.props.item.code) && this.props.brothers[old.code]) {
                            this.props.brothers[old.code].setState({focused: false});
                        }
                    }}
                    handleAddToBasket = {()=>{
                        this.setState({inOrder: true})
                    }}
                />
            </Stack>
        </Box>
    }
}

export default ProductsRows;
