import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import BasketRow from "./BasketRow";
import * as React from "react";
import {Box} from "@mui/material";
import BasketSummary from "./BasketSummary";

export function Basket(api) {
    return (
        <><Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
            >
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                        VELKOOBCHOD Orion spol. s r.o. - Objednávky INDECOR
                    </Typography>
                    <BasketRow api={api}/>
                </Toolbar>
            </AppBar>
        </Box>
        <Box component="main" sx={{ flexGrow: 1, p: 3}}>
            <Toolbar/>
            <div>
                <BasketSummary api={api} />
            </div>
        </Box>
        </>
    )
}

export default Basket;
