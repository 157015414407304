import React, {useEffect, useRef, useState} from "react";
import { Button, IconButton,  Stack, TextField} from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddShoppingCartSharpIcon from '@mui/icons-material/AddShoppingCartSharp';
import {useDispatch} from "react-redux";
import {addItem} from "../../libs/Store/basket";


function AddToBasket(props) {
    const inputRef = useRef(null);
    const buttonText = props.inOrder!==''?'Upravit':'Do Košíku'
    const [errorMessage, setErrorMessage] = useState('');
    const [value, setValue] = useState(props.inOrder);
    const dispatch = useDispatch();
    const currency=(props.currency)??'CZK';
    const [error, setError] = useState(!props.inOrder);
    let shift = false;
    const [focused, setFocused] = useState(props.inOrder);

    const currencyFormatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: currency,
    });

    useEffect(()=>{
        if (inputRef && props.focused) {
            if (props.item.code !== focused) {
                setFocused(props.item.code)
                setValue(props.inOrder);
            }
            inputRef.current.focus()
        }
    }, [props.focused, props.item.code, props.inOrder, focused]);

    function handleAddToBasket(item, quant) {
        if (!error) {
            props.api.addItemToOrder(item.code,quant).then(res=>{
                if (res.status === 'ok') {
                    let copy = JSON.parse(JSON.stringify(item)); // nekdy je to ready only, nevim proc, je mi to jedno
                    copy['quant'] = quant;
                    dispatch(addItem(copy))

                }
                props.setSnackbar({children: 'Úspěšně přidáno', severity: 'success'})
                props.handleAddToBasket();
                props.handleNext()

            })
        } else {
            props.setSnackbar({children: errorMessage, severity: 'error'})
        }
    }


    function handleChange() {
        setValue(inputRef.current.value)
        inputRef.current.value = inputRef.current.value.replace(/\D/g, "");
        if (inputRef.current.value.length>4) {
            inputRef.current.value=inputRef.current.value.substr(0,4);
        }
        if (inputRef.current.value<props.item.min) {
            setErrorMessage('Minimální množství je '+props.item.min);
            setError(true);
            return;
        }
        if (props.item.multi && (inputRef.current.value%props.item.min)) {
            setErrorMessage('Objednávat lze pouze násobky '+props.item.min);
            setError(true);
            return;
        }
        if (error) {
            setErrorMessage('');
            setError(false)
        }
    }


    function handleUp() {
        let value = Number(inputRef.current.value);
        let step = Number((shift)?1:props.item.min);
        if (isNaN(value)) value = 0;
        inputRef.current.value = value+step;
        handleChange();
    }


    function handleDown() {
        let value = Number(inputRef.current.value);
        let step = Number((shift)?1:props.item.min);
        if (isNaN(value)) value = 0;
        inputRef.current.value = Math.max(0, (value-step));
        handleChange();
    }

    return <Stack direction={props.type==='detail'?'row':'column'} spacing={1} alignItems={'flex-end'}>
        <div className={'price'}>Cena za ks: {currencyFormatter.format(props.item.price)}</div>
        <Stack direction={'row'} spacing={0}>
            <IconButton onClick={()=>{handleDown();}} color={'primary'} variant={'contained'}><IndeterminateCheckBoxIcon /></IconButton>
            <TextField
                hiddenLabel
                size={'small'} variant={'filled'} /*onChange={handleChangeInput}*/
                inputRef={inputRef}
                error={error}
                onKeyPress={ev=>{
                    if (ev.key === 'Enter') {
                        handleAddToBasket(props.item, inputRef.current.value)
                    } else if(ev.key === ' ') {
                        props.handleToggleView()
                    }
                }}
                onKeyDown={ev=>{
                    if (ev.key === 'ArrowDown') {
                        // if (inputRef.current.selectionStart === inputRef.current.value.length) {
                            props.handleNext();
                        // }
                    } else if (ev.key === 'ArrowUp') {
                        // if (inputRef.current.selectionStart === 0) {
                            props.handlePrev();
                        // }
                    } else if (ev.key === 'Shift') {
                        shift = true;
                    } else if (ev.key === 'ArrowRight') {
                        handleUp();
                    } else if (ev.key === 'ArrowLeft') {
                        handleDown();
                    }
                }}
                onKeyUp={ev=>{
                    if (ev.key === 'Shift') {
                        shift = false;
                    }
                }}
                value={value}
                onChange={()=>{handleChange();}}
                onFocus={()=>{props.onFocused(props.item);}}
            />
                 <IconButton onClick={()=>{
                     handleUp()
                 }
                 } color={'primary'} variant={'contained'}><AddBoxIcon /></IconButton>
        </Stack>
        <Button onClick={()=>{handleAddToBasket(props.item, inputRef.current.value)}} disabled={error}
                size={'small'} variant={'contained'}><AddShoppingCartSharpIcon />{buttonText}</Button>
    </Stack>
}
export default AddToBasket;
