import {Box, CircularProgress} from "@mui/material";

function Loader(props) {
    const color = (props.color) ? props.color : 'primary';
    const size = (props.size) ? props.size : 50;
    const width = (props.width) ? props.width : '100%';
    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', width: width }}>
        <CircularProgress size={size} color={color} />
    </Box>
}

export default Loader;
