import React, {useState} from 'react';
import {Box, IconButton, Tooltip} from "@mui/material";
import AddToBasket from "./AddToBasket";
import ProductDescription from "./ProductDescription";
import {useSelector} from "react-redux";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function DetailView(props) {

    const imageDimension = 600;
    const items = useSelector((state) => state.basket.items);
    const [item, setItem] = useState((props.focused) ? props.focused : props.products[0]);
    const inOrder = (items[item.code] !== undefined) ? items[item.code].quant:'';

    let previous = null;
    let next = null;
    let i=0;
    for (i=0;i<props.products.length;i++) {
        if (props.products[i].code === item.code) {
            break;
        }
    }
    if (i>0) previous = props.products[(i-1)];
    if ((i+1)<=props.products.length) next = props.products[(i+1)];

    function handleNext() {
        let nextItem = props.handleNext();
        if (item.code !== nextItem.code) {
            setItem(nextItem);
        }
    }

    function handlePrev() {
        let prevItem = props.handlePrev();
        if (item.code !== prevItem.code) {
            setItem(prevItem);
        }
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {previous&&(
                <Tooltip title={'Předchozí položka'}>
                    <IconButton
                        onClick={()=>{
                            handlePrev();
                        }}
                        size={'large'}><KeyboardArrowLeftIcon /></IconButton>
                </Tooltip>)}
            <Box sx={{width: (imageDimension+10)+'px'}}>
                <img src={props.api.getImageSrc(item.code, imageDimension)}  alt={item.name} width={imageDimension} />
            </Box>
            <Box sx={{flexGrow: 1}} className={'product-row' + (inOrder?' inOrder':'')}>
                <Box><ProductDescription item={item} /></Box>
                <Box sx={{mt: 5}}><AddToBasket
                    {...props}
                    currency={props.api.user.currency}
                    handleNext={()=>{handleNext()}}
                    handlePrev={()=>{handlePrev()}}
                    type={'detail'} item={item}
                    inOrder={inOrder} /></Box>
            </Box>
            {next&&(
                <Tooltip title={'Další položka'}>
                    <IconButton
                        onClick={()=>{handleNext()}}
                        size={'large'}><KeyboardArrowRightIcon /></IconButton>
                </Tooltip>)}
        </Box>
    )
}

export default DetailView;
