import {Fragment}  from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Login from "./modules/Login/Login";
import Api from "./libs/Api/Api";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {themeOptions} from "./scss/ThemeOptoins";
import { csCZ } from "@mui/material/locale";
import BasePage from "./modules/BasePage/BasePage";
import Basket from "./modules/Basket/Basket";
import Account from "./modules/Account/Account"
import {csCZ as czDataGrid} from '@mui/x-data-grid'



function App() {
    const api = new Api();
    const basket = new Basket(api);
    const theme = createTheme(themeOptions, csCZ, czDataGrid);

    if (api.user.logged) {
        return (
            <div className="App">
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Routes>
                            <Route path={'/account'} element={<Account api={api} />} />
                            <Route path={'/basket'} element={basket} />
                            <Route path={'*'} element={<BasePage basket={basket} api={api}/>} />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </div>
        );
    } else {
        return (
            <Fragment>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path={'*'} element={<Login api={api}/>} />
                    </Routes>
                </BrowserRouter>
                </ThemeProvider>
            </Fragment>
        )
    }

}

export default App;
