export const themeOptions = {
    palette: {
        type: 'dark',
        divider: 'rgba(18,1,1,0.12)',
        primary: {
            main: '#F591BD',
            contrastText: '#1f1f1e',
        },
    }
};
