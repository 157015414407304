import {createSlice} from '@reduxjs/toolkit';
import {min_order} from "../../config/config";

export const basketSlice = createSlice({
    name: 'basket',
    initialState: {
        items: {},
        loaded: false,
        orderId: null,
        updated: false,
        totalPrice: 0,
        totalQuant: 0,
        currency: 'CZK',
        categories: {},
        min_order: min_order
    },
    reducers: {
        addItem: (state, item) => {
            state.items[item.payload.code] = item.payload;
            state.updated = true
        },
        setItems (state, items) {
            state.items = items.payload
        },
        removeItem: (state, item) => {
            delete state.items[item.payload.code]
            state.updated = true
        },
        setLoaded: (state) => {
            state.loaded = true
        },
        setOrderId: (state, item) => {
            state.orderId = item.payload
        },
        setUpdatedResolved: (state) => {
            state.updated = false;
        },
        setTotalPrice: (state, price) => {
            state.totalPrice = price.payload;
        },
        setTotalCount: (state, quant) => {
            state.totalQuant = quant.payload
        },
        setCurrency: (state, currency) => {
            state.currency = currency.payload
        },
        setMinOrder: (state, min_order) => {
            state.min_order = min_order.payload
        },
        setAll: (state, data) => {
            state.items = data.payload.items;
            state.loaded = true;
            state.orderId = data.payload.orderId;
            state.updated = false;
            state.totalPrice = data.payload.totalPrice;
            state.totalQuant = data.payload.totalQuant;
            state.currency = data.payload.currency;
            state.min_order = data.payload.min_order;
        },
        clearOrder: (state) => {
            state.items = {};
            state.loaded = false;
            state.orderId = null;
            state.updated = false;
            state.totalPrice = 0;
            state.totalQuant = 0;
        }

    }
})

export const {addItem, removeItem, setLoaded, setOrderId, setUpdatedResolved, setItems,setTotalPrice, setTotalCount, setCurrency, setAll, clearOrder, setMinOrder} = basketSlice.actions;

export default basketSlice.reducer;
