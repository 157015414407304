import React from 'react';
import {
    Alert,
    Box,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Snackbar, TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/DeleteForever';
import {useNavigate} from "react-router-dom";
import Loader from "../../libs/Loader/Loader";
import {DataGrid} from '@mui/x-data-grid'
import {useDispatch, useSelector} from "react-redux";
import {addItem, clearOrder, removeItem} from "../../libs/Store/basket";
import ClearIcon from '@mui/icons-material/Clear';

function DeleteOrderItem(props, item) {
    const [confirmed, setConfirmed] = React.useState(false);
    const dispatch = useDispatch()

    if (confirmed === false) {
        return <Tooltip title={'Smazat'}><IconButton onClick={()=>{setConfirmed()}} color={'error'}><DeleteIcon/></IconButton></Tooltip>
    } else {
        return <Tooltip title={'Smazat'}><Button onClick={()=>{
            props.api.deleteItemFromOrder(item.code).then(res=>{
                if (res.status === 'ok') {
                    dispatch(removeItem(item.code));
                }
            })
        }} color={'error'}><DeleteIcon/>Potvrdit</Button></Tooltip>
    }
}

function BasketSummary(props) {
    const navigate = useNavigate();
    const loaded = useSelector((state) => state.basket.loaded);
    const objectItems = useSelector((state) => state.basket.items);
    const [pageHeight, setPageHeight] = React.useState(window.innerHeight-112);
    const currency = useSelector((state) => state.basket.currency);
    const totalPrice = useSelector((state) => state.basket.totalPrice);
    const min_order = useSelector((state) => state.basket.min_order);
    const [snackBar, setSnackBar] = React.useState(null);
    const dispatch = useDispatch()
    const [sendOrderOpened, openSendOrder] = React.useState(false);
    const [deleteOrderOpened, openDeleteOrder] = React.useState(false);
    const [noteOrder, setNoteOrder] = React.useState('');
    const [buttonDisabled, setButtonDisabled] = React.useState(false);

    const items = [];

    if (Object.keys(objectItems).length) {
        for (let code in objectItems) {
            items.push(objectItems[code])
        }
    }


    const currencyFormatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: currency,
    });

    const columns = [
        {field: 'code', headerName: 'Kód', width: 100},
        {field: 'image', headerName: 'Obrázek', width: 75, renderCell:(params) => {
            return <img src={props.api.getImageSrc(params.row.code, 50)} alt={params.row.code} />}},
        {field: 'name', headerName: 'Název', width: 500},
        {field: 'quant', headerName: 'Ks', width: 80, type: 'number', editable: true, sortable: false, renderCell:(params)=>{
                return <Tooltip title={'Dvojklikem upravíte'}><span className="table-cell-trucate">{params.formattedValue}</span></Tooltip>;
            }},
        {field: 'price', headerName: 'J. cena', width: 80, type: 'number'},
        {field: 'row_price', headerName: 'Cena', width: 120, type: 'number', valueFormatter: ({value}) => currencyFormatter.format(Number(value))},
        {field: 'action', headerName: 'Možnosti', width: 130, renderCell: (params)=>{
                return DeleteOrderItem({...props}, params.row);
            }}
    ];

    window.addEventListener('resize',()=>{
        setPageHeight(window.innerHeight-112)
    })
    const content = (loaded) ?
        <Box sx={{flex: 1, flexGrow: 1}}>
            <DataGrid
                processRowUpdate={async (newValue, oldValue)=>{
                    const mutation = newValue.quant !== oldValue.quant;
                    if (mutation) {
                        if (newValue.quant<newValue.min_order) {
                            setSnackBar({children: 'Minimální množství pro objednání: '+newValue.min_order,severity: 'error'});
                            return oldValue;
                        }
                        if (newValue.multi && (newValue.quant%newValue.min_order)) {
                            setSnackBar({children: 'Povoleny pouze násobky: '+newValue.min_order,severity: 'error'});
                            return oldValue;
                        }
                        props.api.addItemToOrder(newValue.code, newValue.quant).then(res=>{
                            if (res.status === 'ok') {
                                dispatch(addItem(newValue))
                                setSnackBar({children: 'Upraveno: '+newValue.code,severity: 'success'});
                            } else {
                                setSnackBar({children: 'Neznámá chyba backendu', severity: 'error'});
                            }
                        })
                        return newValue;
                    } else {
                        return oldValue; // Nothing was changed
                    }
                }}
                experimentalFeatures={{ newEditingApi: true }} rows={items} columns={columns} />
            {!!snackBar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={()=>{setSnackBar(null)}}
                    autoHideDuration={3000}
                >
                    <Alert {...snackBar} />
                </Snackbar>
            )}
        </Box> : <Loader />;

    return <Box sx={{display: "flex", flexFlow: 'column', height: pageHeight}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Button onClick={()=>{navigate('/');}} startIcon={<ArrowBack />}>
                Pokračovat v objednávání
            </Button>
            <Divider sx={{m:2}} orientation={"vertical"}/>
            <h2 style={{margin: 0, flexGrow: 1}}>Souhrn objednávky</h2>
            <Box sx={{flexGrow: 1}}><Button
            onClick={()=>{openDeleteOrder(true)}}
                color={'error'} startIcon={<ClearIcon />}>Smazat celou objednávku</Button></Box>
            <Typography sx={{mr:2}} variant={'body2'}>Ceny jsou uvedeny bez DPH. {min_order>totalPrice && 'Minimální objednávka: '+currencyFormatter.format(Number(min_order))+', zbývá: '+currencyFormatter.format(Number(min_order-totalPrice))}</Typography>
            <Button disabled={totalPrice<min_order} onClick={()=>{openSendOrder(true)}}
                variant={'contained'}>Odeslat objednávku</Button>
            <Dialog open={deleteOrderOpened} onClose={()=>{openDeleteOrder(false)}}>
                <DialogTitle>Smazání objednávky</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potvrzením <strong>SMAŽETE</strong> celou objednávku celkové hodnotě {currencyFormatter.format(Number(totalPrice))} bez DPH.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{openDeleteOrder(false)}}>Zrušit</Button>
                    <Button color={'error'} onClick={()=>{
                        props.api.deleteOrder().then(res=>{
                            openDeleteOrder(false)
                            if (res.status === 'ok') {
                                dispatch(clearOrder());
                            }
                        })
                    }}>SMAZAT</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={sendOrderOpened} onClose={()=>{openSendOrder(false)}}>
                <DialogTitle>Odeslání objednávky</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Potvrzením závazně objednáte vybrané položky v celkové hodnotě {currencyFormatter.format(Number(totalPrice))} bez DPH.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="note"
                        label="Poznámka"
                        type="textarea"
                        fullWidth
                        variant="standard"
                        value={noteOrder}
                        onChange={(event)=>{
                            setNoteOrder(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{openSendOrder(false)}}>Zrušit</Button>
                    {buttonDisabled?<Loader width={91} size={30}/>:<Button onClick={(elm)=>{
                        setButtonDisabled(true);
                        props.api.sendOrder(noteOrder).then(res=>{
                            setButtonDisabled(false);
                            if (res.status === 'ok') {
                                dispatch(clearOrder());
                                openSendOrder(false);
                                navigate('/');
                            } else {
                                setSnackBar({children: 'Objednávku se nepodařilo odeslat', severity: 'error'})
                            }
                        });
                        }}>Odeslat</Button>}
                </DialogActions>
            </Dialog>
        </Box>

        <Divider sx={{m: 2}} />
        {content}
    </Box>
}

export default BasketSummary;
