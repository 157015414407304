import {Component} from "react";
import LoginForm from "./LoginForm";
import {Grid} from "@mui/material";

export default class Login extends Component {
    render() {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                    <LoginForm api={this.props.api} />
            </Grid>
        )
    }
}
