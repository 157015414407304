import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setLoaded, setUpdatedResolved, setAll} from "../../libs/Store/basket";
import Loader from "../../libs/Loader/Loader";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";

export function BasketRow(props)  {
    const loaded = useSelector((state) => state.basket.loaded);
    const items = useSelector((state) => state.basket.items);
    const updated = useSelector((state) => state.basket.updated);
    const totalPrice = useSelector((state) => state.basket.totalPrice);
    const currency = useSelector((state) => state.basket.currency);
    const min_order = useSelector((state) => state.basket.min_order);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function getOrderInfo() {
        return props.api.getOrderSummary().then(res => {
            let items = {};
            if (res.items.length) {
                for (let index in res.items) {
                    items[res.items[index].code] = res.items[index];
                }
            }
            dispatch(setAll({
                items: items,
                loaded: true,
                updated: false,
                currency: res.currency,
                totalPrice: res.total_price,
                totalQuant: res.total_quantity,
                min_order: res.price_limit,
                orderId: res.order_id}));
        });
    }

    if (loaded === false) {
        dispatch(setLoaded());
        getOrderInfo();
        return <div><Loader color={'inherit'} /></div>
    }

    if (updated) {
        dispatch(setUpdatedResolved());
        getOrderInfo();
        return <div><Loader color={'inherit'} /></div>
    }


    const currencyFormatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: currency,
    });

    const quantity = Object.keys(items).length
    if (quantity === 0) {
        return <div>Prázdná objednávka</div>
    }
    let label = 'Položka';
    if (quantity>4) {
        label = 'Položek';
    } else if(quantity>2) {
        label = 'Položky';
    }
    return <Stack
        onClick={()=>{
            navigate("/basket");
        }}
        container direction={'row'} alignItems={'center'} sx={{width: 'auto', cursor: 'pointer'}}>
       <ShoppingCartOutlinedIcon />
        {/*<Grid item>{quantity} {label}, {totalCount} ks, {currencyFormatter.format(Number(totalPrice))}</Grid>*/}
        <span>{quantity} {label}, <strong>{currencyFormatter.format(Number(totalPrice))}</strong></span>
        {(totalPrice<min_order) && (<span>, Do minimální objednávky zbývá: {currencyFormatter.format(Number(min_order-totalPrice))}</span>)}
    </Stack>
}

export default BasketRow;
