import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Loader from "../../libs/Loader/Loader";
import {
    Button,
    Checkbox, FormControl, FormControlLabel,
    ListItemIcon,
    Menu,
    MenuItem, Radio, RadioGroup,
    Typography
} from "@mui/material";
import Catalogue from "../Catalogue/Catalogue";
import BasketRow from "../Basket/BasketRow";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {useNavigate} from "react-router-dom";
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';

const drawerWidth = 400;

function ResponsiveDrawer(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();
    const [openCat, setOpenCat] = React.useState(null);
    const [groups, setGroups] = React.useState(null);
    const [skipGroups, setSkipGroups] = React.useState([]);

    const catRef = React.useRef();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [categories, setCategories] = React.useState([]);

    if (categories.length<1) {
        props.api.getCategories().then(res=>{
            let categories = res.categories;
            for (let index in categories) {
                categories[index].checked = true;
            }
            categories.sort((a,b)=> {return a.label<b.label?-1:1})
            setCategories(categories)
        });
    }
    const loading = (
           <Box sx={{mt: 5}}>
               <Loader />
           </Box>
    );

    const children = groups ? <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}> {groups.map((group, index) => {
            return <FormControlLabel key={'group_'+group.code}
                label={group.label===''?'Bez Skupiny':group.label}
                control={<Checkbox name={group.code} checked={skipGroups.indexOf(group.code) === -1} onChange={(event)=>{
                    if (event.target.checked) {
                        let skip_index
                        if ((skip_index = skipGroups.indexOf(group.code)) !== -1) {
                            skipGroups.splice(skip_index,1);
                            setSkipGroups([...skipGroups]);
                            catRef.current.skipGroupsUpdate();
                        }
                    } else {
                        if (skipGroups.indexOf(group.code) === -1) {
                            skipGroups.push(group.code);
                            setSkipGroups([...skipGroups]);
                            catRef.current.skipGroupsUpdate();
                        }
                    }
                }} />}
            /> })}</Box> : null;

    const drawer = (
        <div>
            <Toolbar>
                <img alt={'Indecor logo'} width={150} src={'indecor_logo.png'} />
            </Toolbar>
            {/*<Toolbar>*/}
            {/*    <TextField label={'Filtr kategorie'} size={'small'} onChange={val=>setCatFilter(val.target.value)} />*/}
            {/*</Toolbar>*/}
            <Divider />
            <List>
                <FormControl sx={{m:3}}>
                    <Typography variant={'h5'}>Kategorie:</Typography>
                    <Divider sx={{mb: 2}} />
                    <RadioGroup
                        aria-labelledby={'categories-label'}
                        defaultValue={'all'}
                        name={'categories'}
                        onChange={(event)=>{
                            const index = event.target.value;
                            let tmp = [];
                            for (let i = 0;categories.length>i;i++) {
                                tmp.push(categories[i]);
                                if (tmp[i].code === index) {
                                    tmp[i].checked = true;
                                    setOpenCat(index)
                                    setGroups(tmp[i].groups)
                                } else if (index === 'all') {
                                    tmp[i].checked = true;
                                } else {
                                    tmp[i].checked = false;
                                }
                            }
                            setCategories(tmp);
                            setSkipGroups([]);
                        }}
                    >
                    <FormControlLabel
                        value={'all'}
                        control={<Radio />}
                        label={'Vše'}
                        onChange={()=>{
                            setOpenCat(false);
                            setGroups(false);
                        }}
                    />
                    {categories.map((category, index) => {
                        return <div key={'category_'+category.code}><FormControlLabel
                                    value={category.code}
                                    datagroups={category.groups}
                                    control={<Radio />}
                                    label={category.label+' ('+category.quant+')'}
                                />{openCat === category.code ? children : null}</div>;
                    })}
                    </RadioGroup>
                </FormControl>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    const [anchorUser, setAnchorUser] = React.useState(null);
    const open = Boolean(anchorUser);
    const handleClick = (event) => {
        setAnchorUser(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorUser(null);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { lg: `calc(100% - ${drawerWidth}px)` },
                    ml: { lg: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { lg: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{flexGrow: 1}}><BasketRow api={props.api} /></Box>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}

                        color={'inherit'} startIcon={<AccountBoxIcon/>}>
                        {props.api.user.username}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorUser}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                            <MenuItem onClick={()=>{
                                navigate("/account");
                            }}>
                                <ListItemIcon>
                                    <KeyIcon fontSize={'small'}/>
                                </ListItemIcon>
                                <ListItemText>Změnit heslo</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={()=>{
                                props.api.logout()
                            }}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize={'small'}/>
                                </ListItemIcon>
                                <ListItemText>Odhlásit</ListItemText>
                            </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {categories.length?drawer:loading}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {categories.length?drawer:loading}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { lg: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Catalogue ref={catRef} api={props.api} categories={categories} skipGroups={skipGroups} />
            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
