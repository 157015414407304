import axios from "axios";
import User from "../User/User";
import ApiException from "./ApiException";
import {API_URL} from "../../config/config";

export default class Api {

    constructor() {
        this.interface = axios.create({withCredentials: true, baseURL: API_URL});
        this.user = new User();
        this.user.load();
    }


    controlResponse(response) {
        if (response.status === 200) {
            let data = response.data;
            if (data.status === 'error') {
                if (data.errno === 3003) {
                    this.user.logout();
                    window.location = '/';
                }
            }
            return data;
        } else {
            throw new ApiException(response.errno, response.message, response.responseCode)
        }
    }


    login(user) {
        this.user = user;
        return this.interface.post('login', {user}).then(res => {return this.controlResponse(res)}).then(
            data => {
                if (data.status === 'ok') {
                    this.user.logged = true;
                    this.user.currency = data.currency;
                    this.user.language = data.language;
                    this.user.save();
                    window.location = '/';
                } else {
                    if (data.status === 3003) {
                        this.user.logout();
                        window.location = '/'
                    }
                    return false;
                }
            }
        )
    }

    addItemToOrder(code, quant) {
        return this.interface.post('add-to-order',{code: code, quant: quant})
            .then(res=>{return this.controlResponse(res)})
    }

    deleteItemFromOrder(code) {
        return this.interface.post('delete-from-order',{code: code})
            .then(res=>{return this.controlResponse(res)})
    }

    getOrderSummary() {
        return this.interface.post('get-order-summary')
            .then(res=>{return this.controlResponse(res)});
    }

    sendOrder(note) {
        return this.interface.post('send-order', {note: note})
            .then(res=>{return this.controlResponse(res)});
    }
    deleteOrder() {
        return this.interface.post('delete-order')
            .then(res=>{return this.controlResponse(res)});
    }
    logout() {
        this.interface.post('logout').then(() => {
            this.user.logout();
            window.location = '/';
        })
    }
    changePassword(oldPassword, newPassword) {
        return this.interface.post('reset-password', {newPassword: newPassword, oldPassword: oldPassword})
            .then(res=>{return this.controlResponse(res)});
    }
    getCategories() {
        return this.interface.post('get-categories').then(res=>{return this.controlResponse(res)})
    }
    getProducts(categories, skipGroups) {
        return this.interface.post('get-products',
            {categories: categories, skipGroups: skipGroups})
            .then(res=>{return this.controlResponse(res)})
    }
    getImageSrc(code, maxDimension) {
        return API_URL + '/image?code='+code+'&dimension='+maxDimension
    }
}
