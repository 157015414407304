import {Alert, Box, Button, Divider, Snackbar, Stack, TextField} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import KeyIcon from '@mui/icons-material/Key';
import * as React from "react";
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

function Account(props) {
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(true);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [snackbar, setSnackBar] = useState(false);

    return <><Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <AppBar position="fixed">
            <Toolbar>
                <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                    Nastavení uživatele
                </Typography>
            </Toolbar>
        </AppBar>
    </Box>
        <Box component="main" sx={{ flexGrow: 1, p: 3}}>
            <Toolbar/>
            <Button onClick={()=>{navigate('/');}} startIcon={<ArrowBack />}>
                Pokračovat v objednávání
            </Button>
            <Divider sx={{m: 2}}/>
            <Stack sx={{m: 5}} spacing={2} justifyContent={'flex-start'} alignItems="flex-start">
                <Typography variant={'h3'}>
                    Uživatel: {props.api.user.username}
                </Typography>
                <Typography variant={'h5'}>Změna hesla:</Typography>
                <Stack direction={'row'}>
                    <TextField onChange={(event)=>{
                        setOldPassword(event.target.value)
                    }} type={'password'} label={'Původní heslo'} />
                    <TextField onChange={(event=>{
                        const value = event.target.value;
                        setNewPassword(value);
                        setDisabled(value&&(value !== repeatPassword))
                    })}
                    sx={{ml: 2}} type={'password'} label={'Nové heslo'} />
                    <TextField onChange={(event=>{
                        const value = event.target.value;
                        setRepeatPassword(value);
                        setDisabled((value&&(value !== newPassword)))
                    })}
                    sx={{ml: 2}} type={'password'} label={'Potvrdit heslo'} />
                </Stack>
                <Button
                    onClick={()=>{props.api.changePassword(oldPassword,newPassword).then(res=>{
                        if (res.status === 'ok') {
                            setSnackBar({children: 'Heslo úspěšně změněno', severity: 'success'});
                        } else if (res.status === 'error') {
                            setSnackBar({children: res.message, severity: 'error'});
                        }
                    })}}
                    disabled={disabled}
                    startIcon={<KeyIcon />} variant={'contained'}>Změnit heslo</Button>
            </Stack>
        </Box>
        {!!snackbar && (
            <Snackbar
                open
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                onClose={()=>{setSnackBar(false)}}
                autoHideDuration={3000}
            >
                <Alert {...snackbar} />
            </Snackbar>
        )}
    </>
}

export default Account;
