import {Stack} from "@mui/material";

function ProductDescription(props) {
    const item = props.item;

    return <div className={'product-description'}>
        <div className={'product-name'}>{item.name}</div>
        <Stack direction={'row'} spacing={1} alignItems={'flex-end'}>
            <div className={'product-code'}>Kód: {item.code},</div>
            <div className={'product-ean'}>EAN: {item.ean}</div>
        </Stack>
        <div className={'product-order-info'}>
            Balení: {item.package}, Minimum: {item.min}, Pouze násobky: {item.multi?'ano':'ne'}, Skladem: {item.quantity}
        </div>
    </div>
}

export default ProductDescription;
