import React, {Component} from "react";
import PropTypes from 'prop-types'
import Loader from "../../libs/Loader/Loader";
import ProductsRows from "./ProductsRows";
import {Alert, Box, Divider, IconButton, Snackbar, TablePagination, TextField, Tooltip} from "@mui/material";
import {Preview, ViewAgenda} from "@mui/icons-material";
import DetailView from "./DetailView";

class Catalogue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            view: 'rows',
            sortType: 'code',
            sortDirection: 'ASC',
            products: [],
            filteredProducts: [],
            perPage: 25,
            page: 0,
            filter: '',
            categories: [],
            snackbar: null,
        }
        this.filterTimer = null;
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.focused = null;
    }

    componentDidMount() {
        this.getProducts();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.categories.length>0) {
            this.checkCategories();
        }
    }

    skipGroupsUpdate() {
        this.getProducts()
    }

    handleFilterChange(value) {
        if (this.filterTimer) {
            clearTimeout(this.filterTimer);
        }
        this.filterTimer = setTimeout(()=>{
            this.setState({
                filter: value.target.value,
                page: 0,
                filteredProducts: this.getFilteredProducts(this.state.products, value.target.value)
            })
        }, 500)
    }

    getFilteredProducts(products, filter) {
        if (filter==='') return products;
        let filtered = [];
        for (let index in products) {
            let product = products[index];
            if (filter.indexOf(" ") === -1) {
                if (String(product.code).indexOf(filter) !== -1) {
                    filtered.push(product);
                    continue;
                }
                if (String(product.ean).indexOf(filter) !== -1) {
                    filtered.push(product);
                    continue;
                }
                let needle = filter.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
                let haystack = product.name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
                if (haystack.indexOf(needle) !== -1) filtered.push(product);
            } else {
                let needle = filter.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
                let haystack = product.name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
                let words = needle.split(" ");
                let found = true;
                for (let i=0;i<words.length;i++) {
                    if (haystack.indexOf(words[i])===-1) {
                        found = false;
                        break;
                    }
                }
                if (found) {
                    filtered.push(product);
                }
            }
        }
        return filtered;
    }

    checkCategories() {
        for (let i=0;i<this.props.categories.length;i++) {
            if (this.props.categories[i].checked) {
                if (this.state.categories.indexOf(this.props.categories[i].code) === -1) {
                    this.setState({loaded: false})
                    this.getProducts();
                    return;
                }
            } else {
                if (this.state.categories.indexOf(this.props.categories[i].code) !== -1) {
                    this.setState({loaded: false})
                    this.getProducts();
                    return;
                }
            }
        }
    }

    getProducts() {
        let categories = [];
        for (let i=0;i<this.props.categories.length;i++) {
            if (this.props.categories[i].checked) {
                categories.push(this.props.categories[i].code);
            }
        }
        if (categories.length) {
            this.setState({categories: categories, products: [], filterProducts:[]}, () => {
                this.props.api.getProducts(categories, this.props.skipGroups, this.state.sortType, this.state.sortDirection, this.state.perPage,this.state.page)
                    .then(res=>{
                        let products = res.products;
                        for (let i=0;i<products.length;i++) {
                            try {
                                products[i].name = JSON.parse(products[i].name)
                            } catch(exception) {

                            }
                        }
                        let filteredProducts = res.products;
                        if (this.state.filter !== '') {
                            filteredProducts = this.getFilteredProducts(filteredProducts, this.state.filter);
                        }
                        this.focused = filteredProducts[0]??null;
                        this.setState({products: res.products, filteredProducts: filteredProducts, page: 0, loaded: true})
                    });
            })
        } else {
            this.setState({categories: categories,products: [], filteredProducts: [], page: 0, loaded: true})
        }
    }


    getShownProducts() {
        let products = this.state.filteredProducts;
        if (products.length === 0) return [];
        products.sort((a, b)=>{
            if (this.state.sortType === 'code') {
                return (a>b)?-1:1;
            }
            return 1;
        })
        let shown = [];
        let limit = Math.min((this.state.page+1)*this.state.perPage, products.length);
        for (let i=(this.state.page)*this.state.perPage;i<limit;i++) {
            if (!this.focused) this.focused = products[i];
            shown.push(products[i]);
        }
        return shown;
    }


    handleNext() {
        let i;
        for (i=0;i<this.state.filteredProducts.length;i++) {
            if (this.state.filteredProducts[i].code === this.focused.code) {
                break;
            }
        }
        if ((i+1)<this.state.filteredProducts.length){
            let page = this.state.page;
            if ((page+1)*this.state.perPage<=(i+1)) {
                page++;
            }
            this.focused = this.state.filteredProducts[(i+1)];
            if (page !== this.state.page) this.setState({page: page});
        }
        return this.focused;
    }

    handlePrev() {
        const item = this.focused;
        let i;
        for (i=0;i<this.state.filteredProducts.length;i++) {
            if (this.state.filteredProducts[i].code === item.code) {
                break;
            }
        }
        if ((i-1)>=0){
            let page = this.state.page;
            if (page*this.state.perPage>(i-1)) {
                page--;
            }
            this.focused = this.state.filteredProducts[(i-1)];
            if (page !== this.state.page) this.setState({page: page});
        }
        return this.focused;
    }


    render() {
        if (this.state.loaded === false) {
            return <Loader/>
        }
        let body;
        let products = this.getShownProducts()
        if (products.length < 1) {
            body = <>
                <div>Nenalezena žádná položka</div>
            </>
        } else {
            body = <>
                {this.state.view === 'rows' && (<>
                    <ProductsRows {...this.props} products={products} focused={this.focused}
                                  handleToggleView={() => {
                                      this.setState({view: 'detail'})
                                  }}
                                  handleNext={() => {
                                      return this.handleNext()
                                  }}
                                  handlePrev={() => {
                                      return this.handlePrev()
                                  }}
                                  currency={this.state.currency}
                                  setSnackbar={(snack) => {
                                      this.setState({snackbar: snack})
                                  }}
                                  onFocused={(focused, view) => {
                                      const old = this.focused;
                                      if (view && (view !== this.state.view)) {
                                          this.setState({view: view})
                                      }
                                      this.focused = focused;
                                      return old;
                                  }}
                    />
                    <TablePagination
                        rowsPerPage={this.state.perPage}
                        page={this.state.page} component={'div'}
                        onPageChange={(page, value) => {
                            this.setState({page: value})
                        }}
                        onRowsPerPageChange={(page, value) => {
                            this.setState({perPage: page.target.value})
                        }
                        }
                        count={this.state.filteredProducts.length}
                    /></>)}
                {this.state.view === 'detail' && (
                    <DetailView {...this.props} products={this.state.filteredProducts} focused={this.focused}
                                handleToggleView={() => {
                                    this.setState({view: 'rows'})
                                }}
                                handleNext={() => {
                                    return this.handleNext()
                                }}
                                handlePrev={() => {
                                    return this.handlePrev()
                                }}
                                currency={this.state.currency}
                                setSnackbar={(snack) => {
                                    this.setState({snackbar: snack})
                                }}
                                onFocused={(focused) => {
                                    this.focused = focused;
                                }}/>
                )}
            </>
        }
        return <>
            <Box sx={{display: 'flex', direction: 'row', alignItems: "center"}}>
                <Box sx={{flexGrow: 1}}>
                    <TextField
                        defaultValue={this.state.filter}
                        label={'Vyhledat položku'} variant={'standard'} onChange={this.handleFilterChange}/>
                </Box>
                <Box sx={{alignItems: 'center'}}>
                    <span>Typ zobrazení:</span>
                    <Tooltip title={'Řádkové zobrazení'}>
                        <IconButton
                            onClick={() => {
                                this.setState({view: 'rows'})
                            }}
                            color={this.state.view === 'rows' ? 'primary' : 'inherit'}><ViewAgenda/></IconButton>
                    </Tooltip>
                    <Tooltip title={'Celoobrazové zobrazení'}>
                        <IconButton
                            onClick={() => {
                                this.setState({view: 'detail'})
                            }}
                            color={this.state.view === 'detail' ? 'primary' : 'inherit'}><Preview/></IconButton>
                    </Tooltip>
                </Box>
                {this.state.view === 'rows' && (<TablePagination
                    rowsPerPage={this.state.perPage}
                    page={this.state.page} component={'div'}
                    onPageChange={(page, value) => {
                        this.setState({page: value})
                    }}
                    onRowsPerPageChange={(page, value) => {
                        this.setState({perPage: page.target.value})
                    }
                    }
                    count={this.state.filteredProducts.length}
                />)}
            </Box>
            <Divider sx={{mb: 2, mt: 2}}/>
            {body}
            {!!this.state.snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={() => {
                        this.setState({snackbar: null})
                    }}
                    autoHideDuration={3000}
                >
                    <Alert {...this.state.snackbar} />
                </Snackbar>
            )}
        </>
    }
}

Catalogue.propTypes = {
    api: PropTypes.object,
    categories: PropTypes.array
}

export default  Catalogue;
